<template>
    <div class="main">
        <h1>Back Office</h1>
        <div class="form-container" v-if="!$store.state.USER.isLoged">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(login)">
                <div class="input-row">
                    <ValidationProvider  class="input-login identifiant"  rules="required|max:100|min:6" v-slot="{ errors }">
                        <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="identifiant" placeholder="Idenfifiant" />
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="input-row">
                    <ValidationProvider  class="input-login password"  rules="required|max:100|min:6" v-slot="{ errors }">
                        <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="pwd" placeholder="Password" />
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="input-submit">
                    <input type="submit" class="btn-form" value="Login" />
                </div>
            </form>
            </ValidationObserver>
        </div>
        <div class="already-login" v-else>
            <p>You are already login, acess to the codes by clicking <router-link to="/codes">here</router-link>.</p>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import store from './../store'
import axios from 'axios'
import router from './../router'

export default {
    name : 'Login',
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            identifiant : null,
            pwd : null
        }
    },
    methods: {
        login() {
            let params = {
                login : this.identifiant,
                password : this.pwd,
                type : 2
            }

            let config = {
                validateStatus: () => true,
            };

            axios
                .post(store.getters.getURL +'login', params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        store.commit('setToken', response.data.token)
                        let infos = {
                            role : response.data.role,
                            id : response.data.id
                        }
                        store.commit('login', infos)
                        router.push('/winners')
                    } else {
                         store.dispatch('addNotif', {type : 2, content : response.data.message})
                    }
                    })
                .catch((res) => {
                   store.dispatch('addNotif', {type : 2, content : res})
                });


        }
    },
    created() {
        if(store.state.USER.isLoged) {
            store.commit('logout')
        }
    }
}
</script>

<style scoped>

    .main {
        justify-content: center;
    }

    h1 {
        font-family: 'Arimo_bold';
        font-size:30px;
        color:#335c68;
        text-transform: uppercase;
        margin-bottom:40px;
    }

    .input-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        font-family: 'Arimo';
    }

    .input-login input {
        border: 1px solid #d6d6d6;
        width: 240px;
        padding: 10px 35px;
        border-radius: 20px;
        font-family: 'Arimo';
    }

    .input-login input:focus, .input-submit  input:focus{
        outline: none;
    }

    .password input {
        background: url('./../assets/icon-pwd.png');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center left 10px;
    }

    .identifiant input {
        background: url('./../assets/icon-user.png');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center left 10px;
    }

    .input-login input.input-error {
        border-color:#ed2939;
    }

    .btn-form {
        background: #335c68;
        width: 100%;
        color: #fff;
        text-transform: uppercase;
        border: none;
        border-radius: 20px;
        border: 1px solid #335c68;
        padding: 11px 0;
        font-family: 'Arimo_bold';
        font-size: 15px;
        cursor: pointer;
        transition: .5s;
    }

    .btn-form:hover {
        background:#ef7941;
        border-color:#ef7941;
    }

    .notice-error {
        color: #ed2939;
        font-size: 12px;
    }

    p {
        color:#828282;
    }

    p a {
        color:#828282;
        text-decoration: none;
    }

    p a:hover {
        text-decoration: underline;
    }
</style>